import React from 'react';

import { Column, Row, StyledHorizontalRule } from '../../../../components/shared.styled';
import { YearMonthPicker } from '../../../../components/yearMonthPicker/YearMonthPicker';
import { InputFormField, PhoneInputFormField, SelectFormField } from '../../../../formFields';
import { FormField } from '../../../../formValidator';
import { YearAndMonthValidation } from '../../../../utils/customValidation';
import { emptyString, findKeyFromValue } from '../../../../utils/helper';
import { Clonable } from '../../../../utils/Immer.class';
import { EmploymentStatusSelectOptions } from '../../../../utils/selectOptions';
import { validateAddress, validateAddressNew } from '../../../../utils/Validation';
import { InfoCardData } from '../../../Interfaces';
import { EmploymentDispatcher } from '../../Employment.dispatcher';
import { EmploymentInfoFormProps, EmploymentStatus } from './EmploymentStatus';
import * as NewFields from './EmploymentStatusFields';

export class Student extends EmploymentStatus implements Clonable<Student> {
    employerName: FormField.Input;
    phone: FormField.Phone;
    monthlyIncome: FormField.Input;
    schoolAddress: FormField.Input;
    suiteNumber: FormField.Input;
    city: FormField.Input;
    state: FormField.Select;
    zip: FormField.Input;
    yearsEmployed: FormField.Input;
    monthsEmployed: FormField.Input;
    previousEmployment?: EmploymentStatus;

    constructor(me?: Student) {
        super({ ...me, status: NewFields.newStatus(EmploymentStatusSelectOptions.Student) });
        this.employerName = me?.employerName ?? NewFields.newStudentEmployerName();
        this.phone = me?.phone ?? NewFields.newStudentPhone();
        this.monthlyIncome = me?.monthlyIncome ?? NewFields.newStudentMonthlyIncome();
        this.schoolAddress = me?.schoolAddress ?? NewFields.newSchoolAddress();
        this.suiteNumber = me?.suiteNumber ?? NewFields.newSuiteNumber();
        this.city = me?.city ?? NewFields.newCity();
        this.state = me?.state ?? NewFields.newState();
        this.zip = me?.zip ?? NewFields.newZip();
        this.yearsEmployed = me?.yearsEmployed ?? NewFields.newYearsEmployed();
        this.monthsEmployed = me?.monthsEmployed ?? NewFields.newMonthsEmployed();
        this.previousEmployment = me?.previousEmployment;
    }
    clone = (): Student => {
        return new Student({ ...this });
    };
    renderEmploymentStatus = (
        dispatcher: EmploymentDispatcher,
        {
            isEmployerAddressRequired,
            isCreditAppOccupationFieldEnabled,
            isTollFreePhoneNumberAllowed,
            isIncomeValidationEnabled,
            enableEmploymentTimeVsAgeValidation,
            yearMonthPickerErrorMessage,
            previousYearMonthPickerErrorMessage,
            updateStreetAddressValidation
        }: EmploymentInfoFormProps
    ) => {
        let yearMonthErrorMessage;
        if (enableEmploymentTimeVsAgeValidation) {
            yearMonthErrorMessage = yearMonthPickerErrorMessage;
        } else {
            const yearAndMonthValidationMessageEmployed = new YearAndMonthValidation(
                this.yearsEmployed.value,
                this.monthsEmployed.value
            );
            yearMonthErrorMessage = yearAndMonthValidationMessageEmployed.getErrorMessage();
        }

        const isEmployerAddressOptional = !isEmployerAddressRequired;
        this.schoolAddress.optional = isEmployerAddressOptional;
        this.city.optional = isEmployerAddressOptional;
        this.state.optional = isEmployerAddressOptional;
        this.zip.optional = isEmployerAddressOptional;
        if (isIncomeValidationEnabled) {
            this.monthlyIncome.maxlength = 6;
            this.monthlyIncome.errorMessage = 'The value must be from $1 to $999,999';
        }
        this.schoolAddress.validationFunction = updateStreetAddressValidation ? validateAddressNew : validateAddress;
        return (
            <>
                <Row>
                    <Column>
                        <SelectFormField formField={this.status} onChangeHandler={dispatcher.updateEmploymentStatusValue} />
                    </Column>
                    <Column>
                        <InputFormField formField={this.employerName} onChangeHandler={dispatcher.updateEmploymentStatusInputs} />
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <PhoneInputFormField formField={this.phone} onChangeHandler={dispatcher.updateEmploymentStatusInputs} />
                    </Column>
                    <Column>
                        <InputFormField formField={this.monthlyIncome} onChangeHandler={dispatcher.updateEmploymentStatusInputs} />
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <InputFormField formField={this.schoolAddress} onChangeHandler={dispatcher.updateEmploymentStatusInputs} />
                    </Column>
                    <Column>
                        <InputFormField formField={this.suiteNumber} onChangeHandler={dispatcher.updateEmploymentStatusInputs} />
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <InputFormField formField={this.city} onChangeHandler={dispatcher.updateEmploymentStatusInputs} />
                    </Column>
                    <Column>
                        <SelectFormField formField={this.state} onChangeHandler={dispatcher.updateEmploymentAddressStateValue} />
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <InputFormField formField={this.zip} onChangeHandler={dispatcher.updateEmploymentStatusInputs} />
                    </Column>
                    <Column>
                        <YearMonthPicker
                            label="How Long Have You Worked Here?"
                            year={this.yearsEmployed}
                            month={this.monthsEmployed}
                            monthChangeHandler={dispatcher.updateMonthsEmployed}
                            yearChangeHandler={dispatcher.updateYearsEmployed}
                            errorMessage={yearMonthErrorMessage}
                        />
                    </Column>
                </Row>
                {this.previousEmployment && (
                    <>
                        <StyledHorizontalRule />
                        {this.previousEmployment.renderEmploymentStatus(dispatcher, {
                            isEmployerAddressRequired,
                            isCreditAppOccupationFieldEnabled,
                            isTollFreePhoneNumberAllowed,
                            isIncomeValidationEnabled,
                            enableEmploymentTimeVsAgeValidation,
                            yearMonthPickerErrorMessage,
                            previousYearMonthPickerErrorMessage,
                            updateStreetAddressValidation
                        })}
                    </>
                )}
            </>
        );
    };

    reviewAdapter = (): InfoCardData => {
        return {
            employmentStatus: {
                label: this.status.label ?? '',
                value: findKeyFromValue(this.status.value, EmploymentStatusSelectOptions) ?? ''
            },
            employerName: {
                label: this.employerName.label ?? '',
                value: this.employerName.value ?? ''
            },
            monthlyIncome: {
                label: this.monthlyIncome.label ?? '',
                value: `$${emptyString(this.monthlyIncome.value) ? 0 : this.monthlyIncome.value}`
            },
            schoolAddress: {
                label: "School's Address",
                value: `${this.schoolAddress.value}${this.suiteNumber.value ? `, ${this.suiteNumber.value}` : ''}` ?? ''
            },
            cityStateZip: {
                label: '',
                value: `${this.city.value}, ${this.state.value} ${this.zip.value}`
            },
            employedMoreThan2Years: {
                label: 'Over 2 Years?',
                value: this.previousEmployment ? 'No' : 'Yes'
            },
            ...this.previousEmployment?.reviewAdapter()
        };
    };
}

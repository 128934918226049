import * as validator from '@makemydeal/dr-common-utils/dist/helpers/validator';

import { calculateAge, emptyString, isProduction } from './helper';
import { EmploymentStatusSelectOptions } from './selectOptions';
import { telephoneAreaCodes } from './telephoneAreaCodes';

const validAptSteTypes = [
    'apt #',
    'APT#',
    '#',
    'APT',
    'apartment',
    'appart',
    'appartment',
    'APP',
    'aprt',
    'AP',
    'ST #',
    'Suite #',
    'ste#',
    'suite#',
    'st#',
    'STE',
    'Suite'
];

const streetTypeValues = [
    ['av', 'avenue', 'ave', 'ave.'],
    ['bv', 'boulevard', 'blvd', 'blvd.', 'blv', 'bulevard'],
    ['ci', 'circle', 'cir', 'circle.'],
    ['ct', 'court', 'court.'],
    ['cr', 'crescent', 'crescent.'],
    ['dr', 'dr.', 'drive', 'drv', 'drive.'],
    ['fw', 'freeway', 'freeway.', 'frway'],
    ['hw', 'highway', 'highway.', 'hway', 'hwy'],
    ['ln', 'lane', 'lane.'],
    ['pk', 'parkway', 'prkway', 'pway', 'pkwy'],
    ['pl', 'place'],
    ['pz', 'plaza', 'plz'],
    ['rd', 'road'],
    ['sq', 'square'],
    ['st', 'street', 'str', 'st.'],
    ['te', 'terrace'],
    ['tr', 'trail', 'trl', 'trl.'],
    ['tp', 'turnpike', 'turnpke', 'trnpike'],
    ['wa', 'way', 'wy'],
    ['path', 'path.', 'pth']
];

const allStreetTypes: string[] = streetTypeValues.reduce((acc, subArr) => acc.concat(subArr), []);

export const validateName = (value: string): boolean => value.length >= 2 && validator.validateName(value);
export const validateEmail = (value: string): boolean => validator.validateEmail(value);
export const validateVerifyEmail = (value: string, email?: string) => {
    const trimmedEmail = email?.trim();
    return trimmedEmail && validator.validateEmail(value) && value === trimmedEmail;
};
export const validatePhoneNumber = (value: string): boolean => {
    return !isTollPhoneNumber(value) && isValidPhoneNumber(value);
};

export const validateEmployedPhoneNumber = (value: string): boolean => {
    // For employed phone numbers toll numbers are allowed.
    return isValidPhoneNumber(value);
};

const isValidPhoneNumber = (value: string): boolean => {
    // Add testing area code for pre-Production environments
    if (!isProduction) {
        // 555 is an area code used by QA test cases
        telephoneAreaCodes.push('555');
    }
    // Check that the phone number has a valid area code and format
    const areaCode = value.slice(1, 4);
    const isValidAreaCode = telephoneAreaCodes.includes(areaCode);

    return (
        // eslint-disable-next-line max-len
        isValidAreaCode && /^\([2-9]{1}\d{2}\)[\d ]*[2-9]{1}\d{2}-\d{4}$/.test(value) // NANP Formatting
    );
};

export const isTollPhoneNumber = (value: string): boolean => {
    const theNum = value.replace(/\D/g, '');
    return /^(\+?1)?(8(00|33|44|55|66|77|88)[2-9]\d{6})$/.test(theNum);
};

export const validateDOB = (value: string | undefined): boolean => {
    if (!value) return false;
    const age = calculateAge(value);
    return (
        !isNaN(age) &&
        -1 < age &&
        age < 100 &&
        value.length > 0 &&
        (/^(0[1]|0[3-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d\d$/i.test(value) ||
            /^02[/](0[1-9]|1[0-9]|2[0-9])[/](19|20)\d\d$/.test(value))
    );
};

export const isValidDate = (value: string | undefined): boolean => {
    if (!value) return false;
    const [month, day, year] = value.split('/');
    const date = new Date(Number(year), +month - 1, Number(day));
    const isValidDate = Boolean(+date) && date.getDate() === Number(day);
    return isValidDate;
};

export const validateSSN = (value: string | undefined, _?: string | undefined, toggle?: string | undefined): boolean => {
    const updateSSNValidation = Boolean(toggle);
    if (!value) return false;
    if (updateSSNValidation) {
        return (
            value.length > 0 &&
            /^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/i.test(value) && // simple SSN
            /^(?!123-45-6789|987-65-4321)[\d-]+$/i.test(value) && // validates against 123-45-6789 and 987-65-4321
            /^(?!\b(\d)\1+-(\d)\1+-(\d)\1+\b)[\d-]+$/i.test(value) && // validates against all same digits (e.g. 555-55-5555)
            /^(?!666)\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/.test(value) && // Cannot start with 666 per api documentation
            /^(?!000)\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/.test(value) && // Cannot start with 000 per api documentation
            /^(?!9)\d{1}\d{2}-\d{2}-\d{4}$/.test(value)
        );
    } else {
        return (
            value.length > 0 &&
            /^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/i.test(value) && // simple SSN
            /^(?!123-45-6789|987-65-4321)[\d-]+$/i.test(value) && // validates against 123-45-6789 and 987-65-4321
            /^(?!\b(\d)\1+-(\d)\1+-(\d)\1+\b)[\d-]+$/i.test(value) && // validates against all same digits (e.g. 555-55-5555)
            /^(?!666)\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/.test(value) // Cannot start with 666 per api documentation
        );
    }
};
export const validateVerifySSN = (value?: string, ssn?: string) => {
    return !emptyString(value) && value !== ssn;
};

export const validateAddress = (value: string): boolean => {
    const MAX_STREET_NUM_LENGTH = 6;
    const MAX_STREET_NAME_LENGTH = 20;
    const MAX_ADDRESS_LENGTH = 26;
    const splitAddress = value.split(' ');
    const streetNumber = splitAddress[0]; // remove street number from address
    const streetName = splitAddress.slice(1).join(' '); // isolate street name from address
    const validateStreetNumber =
        streetNumber.length > 0 &&
        streetNumber.length <= MAX_STREET_NUM_LENGTH &&
        /^[A-Za-z0-9]*[0-9]+[A-Za-z0-9]*$/i.test(streetNumber);
    const validateStreetName =
        streetName.length > 0 &&
        streetName.length <= MAX_STREET_NAME_LENGTH &&
        /^[a-zA-Z0-9 \\-\\/\\.',]+[a-zA-Z0-9 \\-\\/\\.,]*$/i.test(streetName);
    const maxLineCharsOmittingSpacesNotExceeded = streetNumber.length + streetName.length <= MAX_ADDRESS_LENGTH;
    return validateStreetNumber && validateStreetName && maxLineCharsOmittingSpacesNotExceeded;
};
export const validateAddressNew = (value: string): boolean => {
    const MAX_STREET_NUM_LENGTH = 6;
    const MAX_STREET_NAME_LENGTH = 20;
    const MAX_ADDRESS_LENGTH = 26;
    const splitAddress = value
        .trim() // Removing leading and trailing whitespaces
        .replace(/\s+/g, ' ') // Replace extra whitespaces with just one
        .split(' ');
    const streetNumber = splitAddress[0]; // remove street number from address
    const addressArrLength = splitAddress.length;
    const streetName = allStreetTypes.includes(splitAddress[addressArrLength - 1].toLowerCase())
        ? // check if last element is a street type
          splitAddress.slice(1, addressArrLength - 1).join(' ')
        : splitAddress.slice(1).join(' '); // isolate street name from address
    const validateStreetNumber =
        streetNumber.length > 0 &&
        streetNumber.length <= MAX_STREET_NUM_LENGTH &&
        /^[A-Za-z0-9]*[0-9-]+[A-Za-z0-9]*$/i.test(streetNumber);
    const validateStreetName =
        streetName.length > 0 &&
        streetName.length <= MAX_STREET_NAME_LENGTH &&
        /^[a-zA-Z0-9 \\-\\/\\.',-]+[a-zA-Z0-9 \\-\\/\\.,]*$/i.test(streetName);
    const maxLineCharsOmittingSpacesNotExceeded = streetNumber.length + streetName.length <= MAX_ADDRESS_LENGTH;
    return validateStreetNumber && validateStreetName && maxLineCharsOmittingSpacesNotExceeded;
};
export const validateOwnRent = (value: string) => !emptyString(value);
export const validateRelationshipType = (value: string | undefined) => !emptyString(value);
export const validateMaritalStatusType = (value: string) => !emptyString(value);
export const validateCity = (value: string): boolean => {
    return value.length >= 2 && /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/i.test(value);
};
export const validateCounty = (value: string): boolean => {
    return value.length >= 2 && /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/i.test(value);
};
export const validateYear = (value: string): boolean => parseInt(value, 10) < 100 && /^[0-9]*$/i.test(value);
export const validateMonth = (value: string): boolean => parseInt(value, 10) < 12 && /^[0-9]*$/i.test(value);
export const validateState = (value: string) => !emptyString(value);
export const validateMortgage = (value: string) => !emptyString(value) && Number(value) < 100000;
export const validateZipCode = (value: string) => value.length === 5 && validator.validateZipCode(value);
export const validateSuiteApartmentNumber = (value: string): boolean => {
    const validateNumber = /^([a-zA-Z])?(([a-zA-Z-])?\s*)+(([#]?\s*)((\w{1}\d{1,4})|(\d{1,4}\w{1})|(\d{1,4}\w{0})|\w{1}))$/i.test(
        value
    );

    const hasValidAptSteType = validAptSteTypes.some((validType) => value.toLowerCase().includes(validType.toLowerCase()));

    return validateNumber && hasValidAptSteType;
};
export const validateEmployerName = (value: string) => value.length > 2 && /^[a-zA-Z0-9#\\.,']+[a-zA-Z0-9 #\\.,']*$/i.test(value);
export const validateIncome = (value: string) => {
    if (emptyString(value)) {
        return false;
    } else {
        return Number(value) > 0 && value.length <= 6;
    }
};
export const validateEmploymentStatus = (value: string) => Object.values(EmploymentStatusSelectOptions).includes(value);
export const validatedPrivacyNoticeCheckBox = (value: string) => value !== 'false';
export const validatedRegulationBCheckBox = (value: string) => value !== 'false';
export const validateOccupation = (value: string) => !emptyString(value) && value.length <= 26;
export const validateRelationshipTypecoApp = (value: string | undefined, primaryMaritalStatus: string | undefined): boolean =>
    !(primaryMaritalStatus === 'Unmarried' && value === 'Spouse');

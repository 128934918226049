import React from 'react';

import { Column, Row, StyledHorizontalRule } from '../../../../components/shared.styled';
import { YearMonthPicker } from '../../../../components/yearMonthPicker/YearMonthPicker';
import { InputFormField, PhoneInputFormField, SelectFormField } from '../../../../formFields';
import { FormField } from '../../../../formValidator';
import { YearAndMonthValidation } from '../../../../utils/customValidation';
import { emptyString } from '../../../../utils/helper';
import { Clonable } from '../../../../utils/Immer.class';
import { EmploymentStatusSelectOptions } from '../../../../utils/selectOptions';
import { validateAddress, validateAddressNew, validateEmployedPhoneNumber } from '../../../../utils/Validation';
import { InfoCardData } from '../../../Interfaces';
import { EmploymentDispatcher } from '../../Employment.dispatcher';
import { EmploymentInfoFormProps, EmploymentStatus } from './EmploymentStatus';
import * as NewFields from './EmploymentStatusFields';

// This is covering Employed, ActiveMilitary
export class Employed extends EmploymentStatus implements Clonable<Employed> {
    employerName: FormField.Input;
    phone: FormField.Phone;
    monthlyIncome: FormField.Input;
    employerAddress: FormField.Input;
    suiteNumber: FormField.Input;
    city: FormField.Input;
    state: FormField.Select;
    zip: FormField.Input;
    occupation: FormField.Input;
    yearsEmployed: FormField.Input;
    monthsEmployed: FormField.Input;
    previousEmployment?: EmploymentStatus;

    /**
     * @param statusValue to specify if this is for Employed, ActiveMilitary
     * by default Employed
     * @param me @type Employed
     */
    constructor(statusValue?: string, me?: Employed) {
        super({ ...me, status: NewFields.newStatus(statusValue ?? EmploymentStatusSelectOptions.Employed) });
        this.employerName = me?.employerName ?? NewFields.newEmployerName();
        this.phone = me?.phone ?? NewFields.newPhone();
        this.monthlyIncome = me?.monthlyIncome ?? NewFields.newMonthlyIncome();
        this.employerAddress = me?.employerAddress ?? NewFields.newEmployerAddress();
        this.suiteNumber = me?.suiteNumber ?? NewFields.newSuiteNumber();
        this.city = me?.city ?? NewFields.newCity();
        this.state = me?.state ?? NewFields.newState();
        this.zip = me?.zip ?? NewFields.newZip();
        this.yearsEmployed = me?.yearsEmployed ?? NewFields.newYearsEmployed();
        this.monthsEmployed = me?.monthsEmployed ?? NewFields.newMonthsEmployed();
        this.occupation = me?.occupation ?? NewFields.newOccupation();
        this.previousEmployment = me?.previousEmployment;
    }
    clone = (): Employed => {
        return new Employed(this.status.value, { ...this });
    };
    renderEmploymentStatus = (
        dispatcher: EmploymentDispatcher,
        {
            isEmployerAddressRequired,
            isCreditAppOccupationFieldEnabled,
            isTollFreePhoneNumberAllowed,
            isIncomeValidationEnabled,
            enableEmploymentTimeVsAgeValidation,
            yearMonthPickerErrorMessage,
            previousYearMonthPickerErrorMessage,
            updateStreetAddressValidation
        }: EmploymentInfoFormProps
    ) => {
        let yearMonthErrorMessage;
        if (enableEmploymentTimeVsAgeValidation) {
            yearMonthErrorMessage = yearMonthPickerErrorMessage;
        } else {
            const yearAndMonthValidationMessageEmployed = new YearAndMonthValidation(
                this.yearsEmployed.value,
                this.monthsEmployed.value
            );
            yearMonthErrorMessage = yearAndMonthValidationMessageEmployed.getErrorMessage();
        }

        const isEmployerAddressOptional = !isEmployerAddressRequired;

        this.employerAddress.optional = isEmployerAddressOptional;
        this.city.optional = isEmployerAddressOptional;
        this.state.optional = isEmployerAddressOptional;
        this.zip.optional = isEmployerAddressOptional;
        if (isTollFreePhoneNumberAllowed) {
            this.phone.validationFunction = validateEmployedPhoneNumber;
        }
        if (isIncomeValidationEnabled) {
            this.monthlyIncome.maxlength = 6;
            this.monthlyIncome.errorMessage = 'The value must be from $1 to $999,999';
        }
        this.employerAddress.validationFunction = updateStreetAddressValidation ? validateAddressNew : validateAddress;
        return (
            <>
                {isCreditAppOccupationFieldEnabled ? (
                    <>
                        <Row>
                            <Column>
                                <span className="align-field-mobile">
                                    <SelectFormField
                                        formField={this.status}
                                        onChangeHandler={dispatcher.updateEmploymentStatusValue}
                                    />
                                </span>
                            </Column>
                            <Column></Column>
                        </Row>
                        <Row>
                            <Column>
                                <InputFormField
                                    formField={this.employerName}
                                    onChangeHandler={dispatcher.updateEmploymentStatusInputs}
                                />
                            </Column>
                            <Column>
                                <InputFormField
                                    formField={this.occupation}
                                    onChangeHandler={dispatcher.updateEmploymentStatusInputs}
                                />
                            </Column>
                        </Row>
                    </>
                ) : (
                    <>
                        <Row>
                            <Column>
                                <span className="align-field-mobile">
                                    <SelectFormField
                                        formField={this.status}
                                        onChangeHandler={dispatcher.updateEmploymentStatusValue}
                                    />
                                </span>
                            </Column>
                            <Column>
                                <InputFormField
                                    formField={this.employerName}
                                    onChangeHandler={dispatcher.updateEmploymentStatusInputs}
                                />
                            </Column>
                        </Row>
                    </>
                )}
                <Row>
                    <Column>
                        <PhoneInputFormField formField={this.phone} onChangeHandler={dispatcher.updateEmploymentStatusInputs} />
                    </Column>
                    <Column>
                        <InputFormField formField={this.monthlyIncome} onChangeHandler={dispatcher.updateEmploymentStatusInputs} />
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <InputFormField
                            formField={this.employerAddress}
                            onChangeHandler={dispatcher.updateEmploymentStatusInputs}
                        />
                    </Column>
                    <Column>
                        <InputFormField formField={this.suiteNumber} onChangeHandler={dispatcher.updateEmploymentStatusInputs} />
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <InputFormField formField={this.city} onChangeHandler={dispatcher.updateEmploymentStatusInputs} />
                    </Column>
                    <Column>
                        <span className="align-field-mobile">
                            <SelectFormField
                                formField={this.state}
                                onChangeHandler={dispatcher.updateEmploymentAddressStateValue}
                                id="employer-address-state"
                            />
                        </span>
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <InputFormField formField={this.zip} onChangeHandler={dispatcher.updateEmploymentStatusInputs} />
                    </Column>
                    <Column>
                        <YearMonthPicker
                            label="How Long Have You Worked Here?"
                            year={this.yearsEmployed}
                            month={this.monthsEmployed}
                            monthChangeHandler={dispatcher.updateMonthsEmployed}
                            yearChangeHandler={dispatcher.updateYearsEmployed}
                            errorMessage={yearMonthErrorMessage}
                        />
                    </Column>
                </Row>
                {this.previousEmployment && (
                    <>
                        <StyledHorizontalRule />
                        {this.previousEmployment.renderEmploymentStatus(dispatcher, {
                            isEmployerAddressRequired,
                            isCreditAppOccupationFieldEnabled,
                            isTollFreePhoneNumberAllowed,
                            isIncomeValidationEnabled,
                            enableEmploymentTimeVsAgeValidation,
                            yearMonthPickerErrorMessage,
                            previousYearMonthPickerErrorMessage,
                            updateStreetAddressValidation
                        })}
                    </>
                )}
            </>
        );
    };

    reviewAdapter = (): InfoCardData => {
        return {
            employmentStatus: {
                label: this.status.label ?? '',
                value: this.status.value ?? ''
            },
            employerName: {
                label: this.employerName.label ?? '',
                value: this.employerName.value ?? ''
            },
            occupation: {
                label: this.occupation.label ?? '',
                value: this.occupation.value ?? ''
            },
            phone: {
                label: this.phone.label ?? '',
                value: this.phone.value ?? ''
            },
            monthlyIncome: {
                label: this.monthlyIncome.label ?? '',
                value: `$${emptyString(this.monthlyIncome.value) ? 0 : this.monthlyIncome.value}`
            },
            employerAddress: {
                label: "Employer's Address",
                value: `${this.employerAddress.value}${this.suiteNumber.value ? `, ${this.suiteNumber.value}` : ''}` ?? ''
            },
            cityStateZip: {
                label: '',
                value: `${this.city.value}, ${this.state.value} ${this.zip.value}`
            },
            employedMoreThan2Years: {
                label: 'Over 2 Years?',
                value: this.previousEmployment ? 'No' : 'Yes'
            },
            ...this.previousEmployment?.reviewAdapter()
        };
    };
}

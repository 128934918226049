import { FormField, Validator } from '../../../../formValidator';
import { EmploymentStatusSelectOptions } from '../../../../utils/selectOptions';
import { InfoCardData } from '../../../Interfaces';
import { EmploymentDispatcher } from '../../Employment.dispatcher';
import * as NewFields from './EmploymentStatusFields';

export interface EmploymentInfoFormProps {
    isEmployerAddressRequired: boolean;
    isCreditAppOccupationFieldEnabled: boolean;
    isTollFreePhoneNumberAllowed: boolean;
    enableEmploymentTimeVsAgeValidation?: boolean;
    yearMonthPickerErrorMessage: string;
    previousYearMonthPickerErrorMessage: string;
    isIncomeValidationEnabled: boolean;
    updateStreetAddressValidation: boolean;
}

/**
 * an abstract class that holds renderEmploymentStatus & reviewAdapter
 * signature for concrete classes like Employed
 * each concrete class will overrides the constructor
 */
export abstract class EmploymentStatus extends Validator {
    // this field is common between all the statuses
    status: FormField.Select;

    protected constructor(me?: Partial<EmploymentStatus>) {
        super();
        this.status = me?.status ?? NewFields.newStatus(EmploymentStatusSelectOptions.Employed);
    }

    /**
     * This abstract method is implemented by each EmploymentStatus Type
     * @param dispatcher @type EmploymentDispatcher
     * generates @type JSX.Element
     */
    abstract renderEmploymentStatus(dispatcher: EmploymentDispatcher, options?: EmploymentInfoFormProps): JSX.Element;

    /**
     * This abstract method is implemented by each EmploymentStatus Type
     */
    abstract reviewAdapter(): InfoCardData;
}
